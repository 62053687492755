Body {
    font-family: 'Nunito Sans', Helvetica, Arial, sans-serif !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #2d6065 !important;
    font-size: 12px !important;
    background-color: #F5F8FA !important;
    min-height: 100% !important;
    height: auto !important;
    margin: 0 auto -70px !important;
}

.card {
  --bs-card-border-width: 0px !important;
}

.paddingBottom200 {
  padding-bottom: 200px !important;
}

.bg-oval {
  background: url("../images/bg-oval.png") top center no-repeat;
}

.bg-org {
  background: url("../images/img-bg.png") repeat;
  border: 1px #b8b8b860 dashed;
}

.hgrd {
  grid-template-columns: 11fr 1fr
}
.font085 {
  font-size: 0.85em !important;
}
.font095 {
  font-size: 0.95em !important;
}
.font1em {
  font-size: 1em !important;
}
.font110 {
  font-size: 1.1em;
}
.font150 {
  font-size: 1.5em;
}
.font120 {
  font-size: 1.2em;
}
.row {
  padding: 0 10px 0 10px !important;
}
.footer {
  height: 70px;
  background-color: #ffffff;
  bottom: 0 !important;
  position: fixed !important;
  display: block;
  padding: 30px 30px 40px 30px;
  width: 100% !important;
  font-weight: 500;
  color: #4b4b4b !important;
  box-shadow: #cbd6e2ba 0px 4px 8px 1px;
  z-index: 1000;
}

.fullscreen-background {
  height: 100vh;
  width: 100%;
  object-fit: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1000;
  opacity: 0.6;
}

.btn-rounded {
  border-radius: 20px !important;
}

.text-green {
  color: #51A8B1 !important;
}

.text-default {
  color: #2d6065 !important;
}

.text-vermelho {
  color: #c95b5b !important;
}

.text-grey {
  color: #2d6065ab !important;
}
.text-grey-rating {
  color: #cfd0d1 !important; 
}
.rating.text-grey-rating:hover {
  color: #c95b5b !important;
}

.divBorder {
  border: #CBD6E2 solid 1px;
  border-radius: 4px;
  padding: 20px 10px 30px 10px !important;
  box-shadow: #cbd6e2ba 0px 4px 8px 1px;
}

.divBorderClean {
  border: #CBD6E2 solid 1px;
}

.headerBorder {
  box-shadow: #cbd6e2ba 0px 0px 8px 1px;
}

.contentShadow {
  box-shadow: #cbd6e2ba 0px 0px 1px 0px !important;
}

.contentShadowFull {
  box-shadow: #cbd6e2ba 0px 0px 10px 1px !important;
}

.form-control {
  font-size: 1em !important;
  line-height: 2 !important;
  color: #2d6065 !important;
  font-weight: 500 !important;
}

h1 {
  font-size:2.1em !important;
}

.form-check-label {
  padding-left: 5px !important;
}

.btnMargin {
  margin-top: 20px !important;
}

.progresses{
  display: inline-flex;
  align-items: center;
  margin-top:10px;
  margin-bottom:50px;
}

.line{

  width: 120px;
  height: 3px;
  background: #51A8B1;
}
.lineNext {
  width: 120px;
  height: 3px;
  background: #e2e2e2;
}
.stepPending{
  display: flex !important;
  background-color: #e2e2e2;
  color: #2d6065;
  font-size: 14px;
  width: 40px;
  height: 40px;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}
.stepDone{
  display: flex !important;
  background-color: #51A8B1;
  color: #fff;
  font-size: 14px;
  width: 40px;
  height: 40px;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}
.stepActive{
  display: flex !important;
  background-color: #2d6065;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  width: 40px;
  height: 40px;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.noDecoration {
  text-decoration:none !important;
}

.alert-warning {
  color: #2d6065 !important;
  background-color: #FFF3D4 !important;
  border: none !important;
  box-shadow: #cbd6e29b 0px 0px 8px 1px;
}

.formMarginBottom {
  margin-bottom: 200px;
}

.noMarginBottom {
  margin-bottom: 0 !important;
}

.form-select {
  display: block;
  width: 100%;
  padding: 0.75rem 2.25rem 0.75rem 0.75rem !important;
  -moz-padding-start: calc(0.75rem - 3px);
  font-size: 1em !important;
  line-height: 1 !important;
  color: #2d6065 !important;
  background-color: #fff;
  background-image: url(data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%23343a40%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27m2 5 6 6 6-6%27/%3e%3c/svg%3e);
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: 1px solid #ced4da;
  border-radius: 0.375rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

select:focus,
textarea:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="time"]:focus,
input[type="week"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="color"]:focus,
.uneditable-input:focus {
  border: 1px solid #ced4da !important;
  box-shadow: 0px 1px 1px rgba(200, 200, 200, 0.075) inset, 0px 0px 8px rgba(81, 168, 177, 0.5) !important;
}

.container-fluid {
  --bs-gutter-x: 0 !important
}

.side-shadow {
  box-shadow: 2px 12px 6px 0px #cbd6e2ba;
}

#sideMenu {
  width: 100% !important;
  font-size: 1.05em;
}
#sideMenu .nav-item {
  width: 100% !important;
  margin-top: 1px;
  margin-bottom: 1px;
}

.nav-pills .nav-link {
  color: #2d6065 !important;
  margin-top: 1px;
  margin-bottom: 1px;
}

.nav-pills .nav-link:hover {
  background-color: #ebeff2 !important;
}

.nav-pills .nav-link.active {
  background-color: #ebeff2 !important;
}

.nav-pills .nav-link.active:hover {
  background-color: #ebeff2 !important;
}
.nav-tabs .nav-link.active {
  border-color: #f3f3f3 #f3f3f3 #ffffff !important;
}
.nav-tabs {
  border-bottom: #f3f3f3 1px solid !important;
}

.vBorder {
  border-left: #2d60655f solid 2px;
  margin-right: 5px;
  padding-left: 5px;
}

.vBorder > li {
  margin-left: 5px;
}
.navColHover:hover {
  background-color: #ffffff !important;
}
.borderBottom {
  border-bottom: #cbd6e2ba solid 1px;
}
.borderNone {
  border-color: transparent;
}
.border-clean {
  border: solid 1px #f3f3f3 !important;
}
.border-clean2-dot {
  border: dotted 1px #bed0d1 !important;
}

.border-green {
  border: solid 1px #51A8B1 !important;
}
.bg-default {
  background-color: #F5F8FA !important;
}
.bg-vermelho-light {
  background-color: #ffc3c3 !important;
}
.bg-green {
  background-color: #59b0b8 !important;
}
.bg-green-dark {
  background-color: #3d7a7f !important;
}
.bg-green-light {
  background-color: #a0d3d924 !important;
}
.bg-green-light-50 {
  background-color: #a0d3d950 !important;
}
.text-bg-green {
  background-color: #59b0b8 !important;
  color: #fff;
}

.hr-border {
  border-top: solid 1px #a3c0c1 !important;
}

.dropdown-item:hover {
  background-color: #f5f8fa !important;
}
.btn-success {
    --bs-btn-bg: #51A8B1 !important;
    --bs-btn-border-color: #51A8B1 !important;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #38767c !important;
    --bs-btn-hover-border-color: #38767c !important;
    --bs-btn-active-bg: #38767c !important;
    --bs-btn-active-border-color: #51A8B1 !important;
    --bs-btn-disabled-bg: #51A8B1 !important;
    --bs-btn-disabled-border-color: #51A8B1 !important;
}
.btn-outline-success {
    --bs-btn-color: #51A8B1 !important;
    --bs-btn-border-color: #51A8B1 !important;
    --bs-btn-hover-bg: #51A8B1 !important;
    --bs-btn-hover-border-color: #51A8B1 !important;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #51A8B1 !important;
    --bs-btn-active-border-color: #51A8B1 !important;
    --bs-btn-disabled-color: #51A8B1 !important;
    --bs-btn-disabled-border-color: #51A8B1 !important;
}
.btn-warning {
  --bs-btn-bg: #e9a33c !important;
  --bs-btn-border-color: #e9a33c !important;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #c18833 !important;
  --bs-btn-hover-border-color: #c18833 !important;
  --bs-btn-active-bg: #e9a33c !important;
  --bs-btn-active-border-color: #e9a33c !important;
  --bs-btn-disabled-bg: #e9a33c !important;
  --bs-btn-disabled-border-color: #e9a33c !important;
  color: #ffffff !important;
}
.btn-outline-warning {
  --bs-btn-color: #e9a33c !important;
  --bs-btn-border-color: #e9a33c !important;
  --bs-btn-hover-color: #ffffff !important;
  --bs-btn-hover-bg: #e9a33c !important;
  --bs-btn-hover-border-color: #e9a33c !important;
  --bs-btn-focus-shadow-rgb: 255,193,7;
  --bs-btn-active-color: #ffffff !important;
  --bs-btn-active-bg: #e9a33c !important;
  --bs-btn-active-border-color: #e9a33c !important;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #e9a33c !important;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #e9a33c !important;
  --bs-gradient: none;
}
.no-caret::after {
  display: none !important;
}
.btn:hover,
.btn.show {
  border-color: #bed0d1 !important;
}

.btnClean:hover,
.btnClean.show,
.btnClean.focus {
  border: none !important;
}

.borderHeadOrg {
  border-top: 4px #FFB649 solid !important;
  border-bottom: 1px solid #f3f3f3;
  border-left: 1px solid #f3f3f3;
  border-right: 1px solid #f3f3f3;
  border-radius: 4px;
}

.borderGreenOrg {
  border-top: 4px #51a9b1 solid !important;
  border-bottom: 1px solid #f3f3f3;
  border-left: 1px solid #f3f3f3;
  border-right: 1px solid #f3f3f3;
  border-radius: 4px;
}

.p-organizationchart.p-component {
  overflow: scroll;
  height: 100%;
  max-height: 500px;
  max-width: 100%;
  width: 100%;
}

.add-toggler {
  color: #51A8B1;
  background: #ffffff;
  border-radius: 50%;
  position: absolute;
  bottom: -0.95rem;
  margin-left: -0.75rem;
  z-index: 2000;
  left: 50%;
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
  width: 2rem;
  height: 2rem;
  text-decoration: none;
  
}
.add-toggle-icon {
  position: relative;
  top: -0.2rem;
  right: 0.5rem;
  width: 2rem;
  height: 2rem;
  display: inline-block;
  user-select: none;
}

.input-search-radius {
  border-top-left-radius: 50rem !important;
  border-bottom-left-radius: 50rem !important;
}
.button-search-radius {
  border-top-right-radius: 50rem !important;
  border-bottom-right-radius: 50rem !important;
}
.p-sidebar-visible {
  z-index: 11000 !important;
}
.form-switch .form-check-input {
  width: 3rem !important;
  height: 1.75rem !important;
  cursor: pointer !important;
}
.form-switch-label {
  padding-left: 10px !important;
  padding-top: 7px !important;
}
.form-check-input:checked {
  background-color: #51A8B1 !important;
  border-color: #51A8B1 !important;
}
.thover tbody tr:hover td {
  background: #ecf6f7c1 !important;
}
tbody tr.active {
  background: #ecf6f7c1 !important;
}
.table {
  color: #2d6065 !important;
}
.dropdown-header {
  font-size: 1em !important;
  color: #868686 !important;
  font-weight: 600 !important;
}
.selectRadioGroup {
  border: #CBD6E2 solid 1px;
  border-radius: .375rem;
  padding: 0.9em 6em 0.9em 2.5em !important;
  background: #ffffff;
  font-weight: 600;
  color: #2d6065ab !important;
  margin-right: 5px !important;
}

.selectRadioGroup:hover {
  border: #a0d3d9 solid 1px;
  background: #a0d3d924;
  color: #2d6065 !important;
  cursor: pointer;
}

.selectRadioGroup.active {
  border: 1px solid #a0d3d9;
  background: #a0d3d924;
  color: #2d6065 !important;
}

.cursorPointer {
  cursor: pointer !important;
}

.offcanvas-size-xl {
    --bs-offcanvas-width: min(95vw, 600px) !important;
}
.offcanvas-size-xxl {
    --bs-offcanvas-width: min(95vw, 90vw) !important;
}
.offcanvas-size-md { /* add Responsivenes to default offcanvas */
    --bs-offcanvas-width: min(95vw, 400px) !important;
}
.offcanvas-size-sm {
    --bs-offcanvas-width: min(95vw, 250px) !important;
}

.dot-sm {
  height: 8px;
  width: 8px;
  border-radius: 50%;
  display: inline-block;
}
.custom-tooltip {
  --bs-tooltip-bg: #1e3f43 !important;
  font-family: inherit !important;
  font-size: 1em !important;
}

.infinite-scroll-component__outerdiv {
  height: 100% !important;
}

.fullHeight {
  height: calc(100vh + 120px) !important;
}

.filterGrey {
  filter: grayscale(80%) !important;
}
/* MAtrix */
.boxs {
  display: flex !important;
  flex-wrap: wrap;
  width: fit-content;
}
.box {
  flex: 1 1 auto!important;
  margin-top: 1%;
  margin-left: 1%;
  width: 30%;
  height: 20em !important;
  border-radius: 2%;
}

.box > div {
  text-align: center;
}

.boxBlue {
  background-color: #E6F4FF;
}
.boxYellow {
  background-color: #FEF7F2;
}
.boxRed {
  background-color: #FFE9E9;
}
.boxGreenLight {
  background-color: #ECF7F2;
}
.boxGreen {
  background-color: #E1F2EB;
}
.verticalTxtBox {
  align-items: center!important;
  display: flex;
  flex: 1 1 auto!important;
  width: 40px;
  height: 20em;
  margin-top: 1%;
}
.verticalTxtBox > span {
  transform: rotate(270deg);
  display: grid;
  width: 100%;
}

.horizontalTxtBox {
  flex: 1 1 auto!important;
  width: 30%;
  height: 30px;
  margin-top: 1%;
  margin-left: 1%;
}
.horizontalTxtBox > span {
  display: grid;
  text-align: center;
}

.verticalBorderLeft {

  border-left: 2px solid #92ccd2 !important;;

}
